import { env } from '@/env';

import {
  LanguageStatus,
  LlmDefaults,
  LlmProvider,
  TopicStatus,
  TtsDefaults,
  TtsProvider,
} from '@/types';

export const siteName = 'SnackLang';
export const siteDescription = 'Master a language, one bite at a time';
export const siteDomain = 'snacklang.com';

// TODO: try trimming the system prompt and move other instructions to prompt
export const difficultyLevels = [
  { level: 'A1', label: 'Breakthrough/Newbie' },
  { level: 'A2', label: 'Beginner' },
  { level: 'B1', label: 'Pre-Intermediate' },
  { level: 'B2', label: 'Intermediate' },
  { level: 'C1', label: 'Upper-Intermediate' },
  { level: 'C2', label: 'Advanced' },
] as const;

export const defaultTargetLanguageCode = 'zh';
export const defaultSourceLanguageCode = 'en';

export const defaultPromptSettings = {
  sourceLanguageCode: defaultSourceLanguageCode,
  targetLanguageCode: defaultTargetLanguageCode,
  cardCount: 2,
  difficulty: 1,
};

export const maxCardCount = 10;

export const apiProviderTypes = ['llm', 'tts'] as const;
export const apiResourceTypes = ['card', 'audio:card', 'audio:vocabulary'] as const;

export const defaultTokensPerCard = 1200;
export const defaultLlmProvider: LlmProvider = 'openai';
export const defaultTemperature = 0.5;

export const defaultTtsProvider: TtsProvider = 'elevenlabs';
export const defaultAudioDir = 'assets/audio';
export const defaultAudioUrlPath = 'audio';

export const elevenLabsVoices = {
  jamesgao: '4VZIsMPtgggwNg7OXbPY',
};

export const llmProviders = ['openai', 'deepseek', 'anthropic', 'google'] as const;
export const defaultMaxTokens = 8192;
export const llmDefaults: LlmDefaults = {
  openai: {
    // https://platform.openai.com/docs/models
    model: 'gpt-4o',
    temperature: defaultTemperature,
    tokensPerCard: defaultTokensPerCard,
    maxTokens: 32768,
  },
  deepseek: {
    // https://api-docs.deepseek.com/quick_start/pricing
    model: 'deepseek-chat',
    // model: 'deepseek-reasoner',
    temperature: defaultTemperature,
    tokensPerCard: defaultTokensPerCard,
    maxTokens: defaultMaxTokens,
  },
  anthropic: {
    // https://docs.anthropic.com/en/docs/about-claude/models
    model: 'claude-3-5-sonnet-20241022',
    temperature: defaultTemperature,
    tokensPerCard: defaultTokensPerCard,
    maxTokens: defaultMaxTokens,
  },
  google: {
    // https://ai.google.dev/gemini-api/docs/models/gemini
    model: 'gemini-2.0-flash',
    temperature: defaultTemperature,
    tokensPerCard: defaultTokensPerCard,
    maxTokens: defaultMaxTokens,
  },
};

export const llmProviderLinks = {
  openai: {
    docs: env.NEXT_PUBLIC_OPENAI_DOCS_URL ?? 'https://platform.openai.com/docs/overview',
    usage: env.NEXT_PUBLIC_OPENAI_USAGE_URL ?? 'https://platform.openai.com/usage',
    pricing: env.NEXT_PUBLIC_OPENAI_PRICING_URL ?? 'https://platform.openai.com/docs/pricing',
  },
  deepseek: {
    docs: env.NEXT_PUBLIC_DEEPSEEK_DOCS_URL ?? 'https://api-docs.deepseek.com/',
    usage: env.NEXT_PUBLIC_DEEPSEEK_USAGE_URL ?? 'https://platform.deepseek.com/usage',
    pricing:
      env.NEXT_PUBLIC_DEEPSEEK_PRICING_URL ?? 'https://api-docs.deepseek.com/quick_start/pricing',
  },
  anthropic: {
    docs: env.NEXT_PUBLIC_ANTHROPIC_DOCS_URL ?? 'https://docs.anthropic.com/en/docs/welcome',
    usage: env.NEXT_PUBLIC_ANTHROPIC_USAGE_URL ?? 'https://console.anthropic.com/settings/usage',
    pricing: env.NEXT_PUBLIC_ANTHROPIC_PRICING_URL ?? 'https://www.anthropic.com/pricing',
  },
  google: {
    docs: env.NEXT_PUBLIC_GOOGLE_AI_DOCS_URL ?? 'https://ai.google.dev/gemini-api/docs',
    usage: env.NEXT_PUBLIC_GOOGLE_AI_USAGE_URL,
    pricing:
      env.NEXT_PUBLIC_GOOGLE_AI_PRICING_URL ?? 'https://ai.google.dev/gemini-api/docs/pricing',
  },
};

export const ttsProviders = ['awspolly', 'elevenlabs', 'googletts', 'azure'] as const;
export const ttsSpeedOptions = ['x-slow', 'slow', 'medium', 'fast', 'x-fast'] as const;
export const ttsGenderOptions = ['male', 'female', 'other'] as const;

export const ttsDefaults: TtsDefaults = {
  awspolly: {
    voiceId: 'Zhiyu',
    engine: 'neural',
    speed: 'slow',
  },
  elevenlabs: {
    voiceId: elevenLabsVoices.jamesgao,
    language: 'zh',
    model: 'eleven_turbo_v2_5',
    speed: 'slow',
  },
  googletts: {
    voiceId: 'cmn-CN-Wavenet-A',
    language: 'cmn-CN',
    speed: 'slow',
    gender: 'female',
  },
  // narakeet: {
  //   voiceId: 'zh-CN-XiaoxiaoNeural',
  // },
  azure: {
    // https://speech.microsoft.com/portal/voicegallery
    // voiceId: 'zh-CN-YunjianNeural',
    voiceId: 'zh-CN-XiaoxiaoMultilingualNeural',
  },
} as const;

export const ttsProviderLinks = {
  awspolly: {
    docs:
      env.NEXT_PUBLIC_AWS_POLY_DOCS_URL ??
      'https://docs.aws.amazon.com/polly/latest/dg/API_SynthesizeSpeech.html',
    usage: env.NEXT_PUBLIC_AWS_POLY_USAGE_URL,
    pricing: env.NEXT_PUBLIC_AWS_POLY_PRICING_URL ?? 'https://aws.amazon.com/polly/pricing/',
  },
  elevenlabs: {
    docs:
      env.NEXT_PUBLIC_ELEVENLABS_DOCS_URL ??
      'https://elevenlabs.io/docs/api-reference/text-to-speech/convert',
    usage: env.NEXT_PUBLIC_ELEVENLABS_USAGE_URL ?? 'https://elevenlabs.io/app/usage',
    pricing: env.NEXT_PUBLIC_ELEVENLABS_PRICING_URL ?? 'https://elevenlabs.io/pricing',
  },
  googletts: {
    docs:
      env.NEXT_PUBLIC_GOOGLE_SPEECH_DOCS_URL ??
      'https://cloud.google.com/text-to-speech/docs/create-audio-text-client-libraries',
    usage: env.NEXT_PUBLIC_GOOGLE_SPEECH_USAGE_URL,
    pricing:
      env.NEXT_PUBLIC_GOOGLE_SPEECH_PRICING_URL ??
      'https://cloud.google.com/text-to-speech/pricing?hl=en',
  },
  azure: {
    docs:
      env.NEXT_PUBLIC_AZURE_SPEECH_DOCS_URL ??
      'https://learn.microsoft.com/en-us/azure/ai-services/speech-service/rest-text-to-speech',
    usage: env.NEXT_PUBLIC_AZURE_SPEECH_USAGE_URL,
    pricing:
      env.NEXT_PUBLIC_AZURE_SPEECH_PRICING_URL ??
      'https://azure.microsoft.com/en-us/pricing/details/cognitive-services/speech-services/',
  },
};

export const audioTypes = ['card', 'vocabulary'] as const;

export const defaultAdminPageSize = 50;
export const adminStaleTime = 60 * 60 * 1000; // 1 hour

export const cardStatuses = [
  // default after generated
  'generated',
  // has been reviewed and approved to go live
  'approved',
  // live on the site
  'live',
  // needs to be reviewed again
  'review',
  // need editing
  'revise',
  // flagged by a user, but still live
  'flagged',
  // flagged and agreed with user(s)
  'rejected',
  // flagged and not agreed with user(s)
  'reapproved',
  // hidden from the site for other reason
  'hidden',
  // for soft deletes
  'deleted',
] as const;

// TODO: Eventually, don't include 'generated'
export const publicCardStatuses = [
  'generated',
  'approved',
  'live',
  'flagged',
  'reapproved',
] as const;

export const audioStatuses = cardStatuses;
export const defaultAudioStatus = 'generated';

export const vocabularyStatuses = cardStatuses;
export const defaultVocabularyStatus = 'generated';

export const topicStatuses = [
  // default after generated
  'pending',
  // live on the site
  'live',
  // mo longer used for new cards, but old cards with it will still be live
  'retired',
  // cards with this topic will be hidden from the site
  'hidden',
] as const;
export const publicTopicStatuses = ['live'] as const;
export const generateCardsTopicStatuses = ['pending', 'live'];
export const defaultTopicStatus: TopicStatus = 'pending';

export const languageStatuses = ['development', 'staging', 'production', 'archived'] as const;
export const defaultLanguageStatus: LanguageStatus = 'development';

export const anyDifficulty = -1;
export const anyTopic = 'any';
export const anyTargetLanguageCode = 'any';
