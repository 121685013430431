import { clsx, type ClassValue } from 'clsx';
import { randomBytes } from 'crypto';
import humanizeDuration from 'humanize-duration';
import { twMerge } from 'tailwind-merge';
import { fromZodError, isZodErrorLike } from 'zod-validation-error';

import { defaultAudioUrlPath, difficultyLevels } from '@/config';
import { env } from '@/env';

import {
  Audio,
  CardAdmin,
  CardPublic,
  Language,
  LanguageAdmin,
  Topic,
  TopicAdmin,
  TtsProvider,
  VocabularyAdmin,
} from '@/types';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function debug(message: unknown) {
  if (env.DEBUG) console.log(message);
}

export function difficultyLevel(index: number) {
  if (index < 1 || index > difficultyLevels.length) return 'Unknown';

  return difficultyLevels[index - 1].level + ' ' + difficultyLevels[index - 1].label;
}

export function difficultyLevelShort(index: number) {
  if (index < 1 || index > difficultyLevels.length) return '??';

  return difficultyLevels[index - 1].level;
}

export const audioUrl = (audio: Pick<Audio, 'urlPath'>) =>
  `${env.NEXT_PUBLIC_ASSETS_URL_BASE}/${defaultAudioUrlPath}/${audio?.urlPath}`;

export const cardUrl = (card: CardAdmin | CardPublic) =>
  `${env.NEXT_PUBLIC_SITE_URL}/?cardId=${card.id}&targetLanguageCode=${card.targetLanguage.languageCode}`;

export const languageUrl = (language: LanguageAdmin | Language) =>
  `${env.NEXT_PUBLIC_SITE_URL}/?targetLanguageCode=${language.languageCode}`;

export const topicUrl = (topic: TopicAdmin | Topic) =>
  `${env.NEXT_PUBLIC_SITE_URL}/?topic=${topic.label}`;

export const getPreferredAudio = (
  item: CardPublic | CardAdmin | VocabularyAdmin,
  preferredProvider: TtsProvider
) => {
  if (!item?.audio?.length) return undefined;

  const preferredAudio = item.audio.find((audio) => audio.ttsProvider === preferredProvider);

  return preferredAudio || item.audio[0];
};

export function generateRandomHexId() {
  const buffer = randomBytes(16);

  const hexString = Array.from(buffer)
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');

  return hexString.toLowerCase();
}

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) {
    if (isZodErrorLike(error)) {
      return fromZodError(error).message;
    }
    return error.message;
  } else {
    return `Unknown error: ${error}`;
  }
}

export const capitaliseFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const conciseDuration = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
});

type BasenameForAudioParams = {
  audio: Audio;
  ttsProvider: TtsProvider;
  extension?: string;
};
export const basenameForAudio = ({
  audio,
  ttsProvider,
  extension = 'mp3',
}: BasenameForAudioParams) => {
  return `${audio.resourceType}-${ttsProvider}-${audio.id}-${audio.resourceId}.${extension}`;
};

export const hasId = (obj: any): obj is { id: string } => obj && typeof obj.id === 'string';

// acts as predicate for inArray()
export const idList = (id: string | undefined, ids?: string[]): string[] =>
  (ids ?? [id]).filter((id): id is string => id !== undefined);
