import { createEnv } from '@t3-oss/env-nextjs';
import { boolean } from 'boolean';
import { z } from 'zod';

export const env = createEnv({
  server: {
    DATABASE_URL: z.string().url(),
    DATABASE_AUTH_TOKEN: z.preprocess(
      (val) => (process.env.DATABASE_URL === 'http://127.0.0.1:8080' ? undefined : val),
      z.string().min(200).optional()
    ),
    DEBUG: z.preprocess((val) => boolean(val), z.boolean()).default(false),
    GOOGLE_AUTH_CLIENT_ID: z.string().nonempty(),
    GOOGLE_AUTH_CLIENT_SECRET: z.string().nonempty(),
    AUTH_SECRET: z.string().nonempty(),
    ADMIN_USERS: z.string().nonempty(),
    GOOGLE_TAG_MANAGER_ID: z.string().optional(),
    GENERATE_LOCAL_AUDIO: z.preprocess((val) => boolean(val), z.boolean()).default(false),
    LIVE_LANGUAGE_STATUSES: z
      .string()
      .default('production')
      .refine(
        (val) => {
          const statuses = val.split(',').map((s) => s.trim());

          return statuses.every((status) =>
            ['development', 'staging', 'production'].includes(status as any)
          );
        },
        {
          message:
            "LIVE_LANGUAGE_STATUSES must be a comma-separated list of valid language statuses excluding 'archived'",
        }
      ),
    // API keys
    OPENAI_API_KEY: z.string().optional(),
    ELEVENLABS_API_KEY: z.string().optional(),
    AZURE_SPEECH_API_KEY: z.string().optional(),
    DEEPSEEK_API_KEY: z.string().optional(),
    ANTHROPIC_API_KEY: z.string().optional(),
    GOOGLE_GENERATIVE_AI_API_KEY: z.string().optional(),
  },
  client: {
    NEXT_PUBLIC_ASSETS_URL_BASE: z.string().url().optional(),
    NEXT_PUBLIC_SITE_URL: z.string().url().optional(),
    NEXT_PUBLIC_COMING_SOON: z.preprocess((val) => boolean(val), z.boolean()).default(false),
    // TTS docs URLs
    NEXT_PUBLIC_AZURE_SPEECH_DOCS_URL: z.string().url().optional(),
    NEXT_PUBLIC_GOOGLE_SPEECH_DOCS_URL: z.string().url().optional(),
    NEXT_PUBLIC_ELEVENLABS_DOCS_URL: z.string().url().optional(),
    NEXT_PUBLIC_AWS_POLY_DOCS_URL: z.string().url().optional(),
    // TTS usage URLs
    NEXT_PUBLIC_AZURE_SPEECH_USAGE_URL: z.string().url().optional(),
    NEXT_PUBLIC_GOOGLE_SPEECH_USAGE_URL: z.string().url().optional(),
    NEXT_PUBLIC_ELEVENLABS_USAGE_URL: z.string().url().optional(),
    NEXT_PUBLIC_AWS_POLY_USAGE_URL: z.string().url().optional(),
    // TTS pricing URLs
    NEXT_PUBLIC_AZURE_SPEECH_PRICING_URL: z.string().url().optional(),
    NEXT_PUBLIC_ELEVENLABS_PRICING_URL: z.string().url().optional(),
    NEXT_PUBLIC_GOOGLE_SPEECH_PRICING_URL: z.string().url().optional(),
    NEXT_PUBLIC_AWS_POLY_PRICING_URL: z.string().url().optional(),
    // LLM docs URLs
    NEXT_PUBLIC_DEEPSEEK_DOCS_URL: z.string().url().optional(),
    NEXT_PUBLIC_ANTHROPIC_DOCS_URL: z.string().url().optional(),
    NEXT_PUBLIC_GOOGLE_AI_DOCS_URL: z.string().url().optional(),
    NEXT_PUBLIC_OPENAI_DOCS_URL: z.string().url().optional(),
    // LLM usage URLs
    NEXT_PUBLIC_DEEPSEEK_USAGE_URL: z.string().url().optional(),
    NEXT_PUBLIC_ANTHROPIC_USAGE_URL: z.string().url().optional(),
    NEXT_PUBLIC_GOOGLE_AI_USAGE_URL: z.string().url().optional(),
    NEXT_PUBLIC_OPENAI_USAGE_URL: z.string().url().optional(),
    // LLM pricing URLs
    NEXT_PUBLIC_DEEPSEEK_PRICING_URL: z.string().url().optional(),
    NEXT_PUBLIC_ANTHROPIC_PRICING_URL: z.string().url().optional(),
    NEXT_PUBLIC_GOOGLE_AI_PRICING_URL: z.string().url().optional(),
    NEXT_PUBLIC_OPENAI_PRICING_URL: z.string().url().optional(),
  },
  runtimeEnv: {
    DATABASE_URL: process.env.DATABASE_URL,
    DATABASE_AUTH_TOKEN: process.env.DATABASE_AUTH_TOKEN,
    DEBUG: process.env.DEBUG,
    GOOGLE_AUTH_CLIENT_ID: process.env.GOOGLE_AUTH_CLIENT_ID,
    GOOGLE_AUTH_CLIENT_SECRET: process.env.GOOGLE_AUTH_CLIENT_SECRET,
    AUTH_SECRET: process.env.AUTH_SECRET,
    ADMIN_USERS: process.env.ADMIN_USERS,
    LIVE_LANGUAGE_STATUSES: process.env.LIVE_LANGUAGE_STATUSES,
    NEXT_PUBLIC_COMING_SOON: process.env.NEXT_PUBLIC_COMING_SOON,
    NEXT_PUBLIC_SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
    NEXT_PUBLIC_ASSETS_URL_BASE: process.env.NEXT_PUBLIC_ASSETS_URL_BASE,
    NEXT_PUBLIC_AZURE_SPEECH_USAGE_URL: process.env.NEXT_PUBLIC_AZURE_SPEECH_USAGE_URL,
    NEXT_PUBLIC_GOOGLE_SPEECH_USAGE_URL: process.env.NEXT_PUBLIC_GOOGLE_SPEECH_USAGE_URL,
    NEXT_PUBLIC_ELEVENLABS_USAGE_URL: process.env.NEXT_PUBLIC_ELEVENLABS_USAGE_URL,
    NEXT_PUBLIC_AWS_POLY_USAGE_URL: process.env.NEXT_PUBLIC_AWS_POLY_USAGE_URL,
    NEXT_PUBLIC_DEEPSEEK_USAGE_URL: process.env.NEXT_PUBLIC_DEEPSEEK_USAGE_URL,
    NEXT_PUBLIC_ANTHROPIC_USAGE_URL: process.env.NEXT_PUBLIC_ANTHROPIC_USAGE_URL,
    NEXT_PUBLIC_GOOGLE_AI_USAGE_URL: process.env.NEXT_PUBLIC_GOOGLE_AI_USAGE_URL,
    NEXT_PUBLIC_OPENAI_USAGE_URL: process.env.NEXT_PUBLIC_OPENAI_USAGE_URL,
    NEXT_PUBLIC_AZURE_SPEECH_DOCS_URL: process.env.NEXT_PUBLIC_AZURE_SPEECH_DOCS_URL,
    NEXT_PUBLIC_GOOGLE_SPEECH_DOCS_URL: process.env.NEXT_PUBLIC_GOOGLE_SPEECH_DOCS_URL,
    NEXT_PUBLIC_ELEVENLABS_DOCS_URL: process.env.NEXT_PUBLIC_ELEVENLABS_DOCS_URL,
    NEXT_PUBLIC_AWS_POLY_DOCS_URL: process.env.NEXT_PUBLIC_AWS_POLY_DOCS_URL,
    NEXT_PUBLIC_DEEPSEEK_DOCS_URL: process.env.NEXT_PUBLIC_DEEPSEEK_DOCS_URL,
    NEXT_PUBLIC_ANTHROPIC_DOCS_URL: process.env.NEXT_PUBLIC_ANTHROPIC_DOCS_URL,
    NEXT_PUBLIC_GOOGLE_AI_DOCS_URL: process.env.NEXT_PUBLIC_GOOGLE_AI_DOCS_URL,
    NEXT_PUBLIC_OPENAI_DOCS_URL: process.env.NEXT_PUBLIC_OPENAI_DOCS_URL,
    NEXT_PUBLIC_AZURE_SPEECH_PRICING_URL: process.env.NEXT_PUBLIC_AZURE_SPEECH_PRICING_URL,
    NEXT_PUBLIC_ELEVENLABS_PRICING_URL: process.env.NEXT_PUBLIC_ELEVENLABS_PRICING_URL,
    NEXT_PUBLIC_GOOGLE_SPEECH_PRICING_URL: process.env.NEXT_PUBLIC_GOOGLE_SPEECH_PRICING_URL,
    NEXT_PUBLIC_AWS_POLY_PRICING_URL: process.env.NEXT_PUBLIC_AWS_POLY_PRICING_URL,
    NEXT_PUBLIC_DEEPSEEK_PRICING_URL: process.env.NEXT_PUBLIC_DEEPSEEK_PRICING_URL,
    NEXT_PUBLIC_ANTHROPIC_PRICING_URL: process.env.NEXT_PUBLIC_ANTHROPIC_PRICING_URL,
    NEXT_PUBLIC_GOOGLE_AI_PRICING_URL: process.env.NEXT_PUBLIC_GOOGLE_AI_PRICING_URL,
    NEXT_PUBLIC_OPENAI_PRICING_URL: process.env.NEXT_PUBLIC_OPENAI_PRICING_URL,
    GOOGLE_TAG_MANAGER_ID: process.env.GOOGLE_TAG_MANAGER_ID,
    GENERATE_LOCAL_AUDIO: process.env.GENERATE_LOCAL_AUDIO,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    ELEVENLABS_API_KEY: process.env.ELEVENLABS_API_KEY,
    AZURE_SPEECH_API_KEY: process.env.AZURE_SPEECH_API_KEY,
    DEEPSEEK_API_KEY: process.env.DEEPSEEK_API_KEY,
    ANTHROPIC_API_KEY: process.env.ANTHROPIC_API_KEY,
    GOOGLE_GENERATIVE_AI_API_KEY: process.env.GOOGLE_GENERATIVE_AI_API_KEY,
  },
});
